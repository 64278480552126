import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

return (
  <>
    <nav className='navbar'>
      <div className='navbar-container'>

        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>      
        <img src="/hmcLogo.png" width="150px"/>   
        </Link>

        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link 
             to='/AboutUs' 
             className='nav-links'
             onClick={closeMobileMenu}>
            אודות
            </Link>
          </li>
          <li  className='nav-item'>
            <Link
              to='/hospice'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              הוספיס בית / טיפול פליאטיבי
            </Link>
          </li>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to='/shekom'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              שיקום בית  &nbsp;<i className='fas fa-caret-down' />
            </Link>
            {dropdown && <Dropdown />}
          </li>    
          
          <li className='nav-item'>
            <Link
              to='/products'
              className='nav-links'
              onClick={closeMobileMenu}
            >
            סיעודי מורכב
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/Bnemet'
              className='nav-links'
              onClick={closeMobileMenu}
            >
            פנימית בית
            </Link>            
          </li>  
          <li className='nav-item'>
            <Link
              to='/sign-up'
              className='nav-links'
              onClick={closeMobileMenu}
            >
            צור קשר
            </Link>            
          </li> 
          <li>
            <Link
              to='/'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
            عربي
            </Link>
          </li>
         
        </ul>
        {button && <Button buttonStyle='btn--outline'>عربي</Button>}
      </div>
     
    </nav>
  </>
);
}
export default Navbar;
