import React from 'react';
import '../../App.css';

import Footer from '../Footer';

function  Default (){ 
            return (  
                <>                      
                <img className="img404" src="/images/404.jpg"/>  
                <Footer /> 
                </>       
        );
    }


export default Default;