export const MenuItems = [
    {
      title: 'שיקום נוירולוגי',
      path: '/ShekomN',
      cName: 'dropdown-link'
    },
    {
      title: 'שיקום אורטופדי',
      path: '/ShekomO',
      cName: 'dropdown-link'
    },
    {
      title: 'שיקום קימום',
      path: '/ShekomK',
      cName: 'dropdown-link'
    }

  ];