import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h1 className="cards__title"> השירותים שלנו</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/hmc_img_1.jpg"
              text="Explore the hidden waterfall deep inside the Amazon Jungle"
              label="שיקום בית"
              path="/services"
            />
            <CardItem
              src="images/hmc_img_2.jpg"
              text="Travel through the Islands of Bali in a Private Cruise"
              label="יחס אישי"
              path="/services"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src="images/hmc_img_3.jpg"
              text="Set Sail in the Atlantic Ocean visiting Uncharted Waters"
              label="פנימית בית"
              path="/services"
            />
            <CardItem
              src="images/hmc_img_4.jpg"
              text="Experience Football on Top of the Himilayan Mountains"
              label="הוספיס בית"
              path="/products"
            />
            <CardItem
              src="images/hmc_img_5.jpg"
              text="Ride through the Sahara Desert on a guided camel tour"
              label="שאלות ותשובות"
              path="/sign-up"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
