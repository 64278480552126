import React from 'react';
import validate from './validateInfo';
import useForm from './useForm';
import './Form.css';

const FormSignup = ({ submitForm }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  return (
    <div className='form-content-right'>
      <form onSubmit={handleSubmit} className='form' noValidate>
        <h1>
        מלא פרטים ונחזור אליך!
        </h1>
        <div className='form-inputs'>
          <label className='form-label'>שם מלא</label>
          <input
            className='form-input'
            type='text'
            name='username'
            placeholder='הזן שם מלא בבקשה'
            value={values.username}
            onChange={handleChange}
          />
          {errors.username && <p>{errors.username}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>אימייל</label>
          <input
            className='form-input'
            type='email'
            name='email'
            placeholder='הזן כתובת אימייל בבקשה'
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && <p>{errors.email}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>נייד</label>
          <input
            className='form-input'
            type='text'
            name='tel'
            placeholder='הזן מס טלפון בבקשה'
            value={values.tel}
            onChange={handleChange}
          />
          {errors.tel && <p>{errors.tel}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>הודעה</label>
          <textarea
            className='form-textarea'            
            name='message'
            placeholder='הזן הודעה בבקשה'
            value={values.message}
            onChange={handleChange}
          />
          {errors.message && <p>{errors.message}</p>}
        </div>       
        <button className='form-input-btn' type='submit'>
        שלח
        </button>    
      </form>
    </div>
  );
};

export default FormSignup;