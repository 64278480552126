import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-container">
      {/* 
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best vacation deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      */}
      <div class="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-address">
            <h1>office@hmchealth.co.il</h1>
            <h2>טלפון : 054-6224402 </h2>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div class="footer-link-items">
            <h2>אודות</h2>
            <Link to="/">צוות</Link>
            <Link to="/">הנהלה</Link>
            <Link to="/">חזון</Link>
          </div>
          <div class="footer-link-items">
            <h2>שיקום בית</h2>
            <Link to="/">שיקום בית נוירולוגי</Link>
            <Link to="/">שיקום בית אורטופדי</Link>
            <Link to="/">שיקום בית קימום</Link>
          </div>
          <div class="footer-link-items">
            <h2>כללי</h2>
            <Link to="/sign-up">שאלות ותשובות</Link>
          </div>
        </div>
      </div>
      <section class="social-media">
        <div class="social-media-wrap">
          {/* <div class="footer-logo">
            <Link to="/" className="social-logo">
              <img src="hmcLogo.png" width="150px" />
            </Link>
          </div> */}
          <small class="website-rights">
            HMC © {currentYear} All rights reserved.
          </small>
          {/* <div class="social-icons">
            <Link
              class="social-icon-link facebook"
              to="/"
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </Link>
            <Link
              class="social-icon-link instagram"
              to="/"
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </Link>
            <Link
              class="social-icon-link youtube"
              to="/"
              target="_blank"
              aria-label="Youtube"
            >
              <i class="fab fa-youtube" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="Twitter"
            >
              <i class="fab fa-twitter" />
            </Link>
            <Link
              class="social-icon-link twitter"
              to="/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i class="fab fa-linkedin" />
            </Link>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Footer;
