import React from 'react';
import '../Paragraph.css';

import Footer from '../Footer';
import Paragraph from '../Paragraph';
import ParagraphImage from '../ParagraphImage';

function shekomO() {
  return (
    <>
      <h1 id="linkUp" className='Page__title'> שיקום אורתופדי  </h1>
      <ul className='Paragraphs__items'>
        <div id="linkUp" />
        <ParagraphImage
          head='מהו תהליך השיקום האורתופדי? '
          prg_img='images/hmc_img_4.jpg'
          text={[
            'לאחר ניתוח או פציעה מתחילה התמודדות חדשה, עם מגבלות וכאבים לא מוכרים.',
            <br />,
            'השיקום מסייע למטופל להסתגל למצב החדש ומנסה להחזיר את המצב לקדמותו עד כמה שאפשר, בצורה מחושבת וזהירה.'
          ]}

        />
        <div id="link1" />

        <div id="link2" />

        <Paragraph
          head='מטרת השיקום האורתופדי :'
          text={['חזרה לעצמאות תנועתית וניידות לאחר הפגיעה או הניתוח ע"י הפחתת כאבים, השבת טווחי תנועה, שיפור שיווי המשקל, חיזוק השרירים ושיפור השליטה השרירית. ',
            <br />,
            'התהליך כולל טיפול במספר מישורים במקביל ומשלב, בין היתר, פיזיותרפיה, ריפוי בעיסוק, קלינאות תקשורת וליווי סוציאלי. ',
            <br />,
            'צוות השיקום יכלול גם רופא מוסמך המתמחה בשיקום ורפואה פיזיקלית. '

          ]}
        />

        <Paragraph
          head='למי מתאים שיקום אורתופדי :'
          text={[<ul>
            <li>שיקום אורתופדי נדרש במקרים של</li>
            <li>שברים במפרק הירך</li>
            <li>החלפת מפרקים</li>
            <li>שברים בגפיים</li>
            <li>קטיעת גפיים</li>
            <li>דלקות פרקים חריפות</li>
            <li>ניתוחי גב</li>
            <li>נקעים או שברים בקרסול</li>
            <li>פציעות או תיקוני גידים בגפיים העליונות</li>
            <li>תסמונת התעלה הקרפלית</li>
            <li>מחלת מפרקים ניוונית</li>
            <li>אוסטיאופורוזיס</li>
            <li>שברי אגן וכן במצבים בריאותיים מולדים ובכל פגיעה גופנית טראומטית</li>
          </ul>
          ]}
        />
        <div id="link3" />



      </ul>
      <Footer />
    </>
  );
}


export default shekomO;