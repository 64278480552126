import React from 'react';
import '../../App.css';
import FormItem from '../formItem';
import { Button } from '../Button';
import Form from '../Form/Form';
import Footer from '../Footer';



const onChange = e => {
  console.log(e.target.value)
}

function signUp() {
  return (
    <>
        <Form /> 
        <Footer />    
    </>
  );
}

export default signUp;