import React from 'react';
import { Link } from 'react-router-dom';

function Paragraph(props) {
  return (
    <>
      <li className='Paragraphs__item'>      
          <h2 className='ParTitle'>{props.head}</h2>             
          <div className='Par__item__info'>          
            <h5 className='Par__item__text'>{props.text}</h5>
          </div>      
      </li>
    </>
  );
}

export default Paragraph;