import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AboutUs from './components/pages/AboutUs';
import Hospice from './components/pages/Hospice';
import Shekom from './components/pages/Shekom';
import ShekomK from './components/pages/ShekomK';
import ShekomO from './components/pages/ShekomO';
import ShekomN from './components/pages/ShekomN';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import ScrollTop from './components/ScrollTop';
import Default from './components/pages/Default';
import Bnemet from './components/pages/Bnemet';

function App() {
  return (
    <>
      <Router>
      <ScrollTop/>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/aboutUs' component={AboutUs} />
          <Route path='/hospice' component={Hospice} />
          <Route path='/shekom' component={Shekom} />
          <Route path='/shekomK' component={ShekomK} />
          <Route path='/shekomO' component={ShekomO} />
          <Route path='/shekomN' component={ShekomN} />
          <Route path='/bnemet' component={Bnemet} />
          <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} />
        

          Bnemet
          <Route component={Default}/>
        </Switch>
      </Router>
    </>
  );
}

export default App;