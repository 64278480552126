import React from 'react';
import '../Paragraph.css';

import Footer from '../Footer';
import Paragraph from '../Paragraph';
import ParagraphImage from '../ParagraphImage';


function shekomN() {
    return (
        <>
            <h1 id="linkUp" className='Page__title'> שיקום נוירולוגי </h1>
            <ul className='Paragraphs__items'>
                <div id="linkUp" />
                <ParagraphImage
                    head=' '
                    prg_img='images/hmc_img_4.jpg'
                    text={[
                        'שיקום נוירולוגי מתייחס לשיקום לאחר כל מצב של פגיעה במערכת העצבים המרכזית, הכוללת את המוח ואת חוט השדרה.',
                        <br />,
                        ' מערכת העצבים היא האחראית על כל הפעולות המוטוריות שלנו, על התחושות שלנו, הרגשות והמחשבות שלנו.',
                        <br />,
                        ' לכן, כל פגיעה במערכת העצבים המרכזית עלולה לגרום לפגיעה כלשהי באחד מתחומי התפקוד שלנו ולהוביל למגבלה וקושי בתפקוד ובעצמאות היומיומיים.'

                    ]}
                />
                <div id="link1" />

                <div id="link2" />

                <Paragraph
                    head='אירוע מוחי Cerebro-Vascular Accident או בקיצור CVA :'
                    text={[
                        'ניתן לכנות גם שבץ מוחי (Stroke) הוא תהליך מהיר בו אנו עלולים לאבד פונקציות ויכולות תפקודיות במוח כתוצאה מהפרעה באספקת הדם למוח. ',
                        <br />,
                        'המוח שלנו אחראי על כל הפעולות, המחשבות והרגשות שלנו, ולכן אופי הפגיעה תלוי באזור בו המוח נפגע, כלומר באזור בו האירוע המוחי התרחש.',
                        <br />,
                        ' המוח שלנו אינו סימטרי, ולכן כל צד במוח שלנו יודע לבצע פעולות אחרות אשר יחד מאפיינות את התפקוד הכללי שלנו.',
                        <br />,
                        'לכן, למשל, אירוע מוחי בצד ימין ייראה שונה מאוד מאשר אירוע מוחי בצד שמאל.',
                        <br />,
                        'לכן, גם תהליך שיקום לאחר אירוע מוחי ייראה שונה מאדם לאדם.',
                        <br />,
                        'חשוב לזכור שהפגיעה בגוף הינה בדרך כלל בצד הנגדי לצד בו הייתה הפגיעה במוח. ',
                        <br />,
                        'כלומר אם האירוע התרחש בצד הימני של המוח אז תופיע חולשה בצד שמאל של הגוף, ולהיפך.'

                    ]}
                />

                <Paragraph
                    head='במצב של אירוע מוחי יכול להתבטא בפגיעות שונות : '
                    text={[<ul>
                        <li>פגיעה קוגניטיבית – הזנחת צד , בעיות זיכרון, ליקויים בתפקודים גבוהים (תפקודי ניהול).</li>
                        <li>פגיעה מוטורית בדיבור – דיסארתריה.</li>
                        <li>פגיעה שפתית – אפאזיה. בהבנה (Wernicke) או בהבעה (Broca) או גם וגם (גלובלית).</li>
                        <li>פגיעה בבליעה – דיספאגיה.</li>
                        <li>פגיעה במצב ערנות, פגיעה במחזור ערות – שינה</li>
                        <li>דלקות פרקים חריפות</li>
                        <li>פגיעה התנהגותית – פגיעה פרונטלית.</li>
                        <li>פגיעה ראשונית או שניונית – פגיעה בסיבולת, עייפות קשה, דיכאון</li>
                    </ul>
                    ]}
                />
                <div id="link3" />



            </ul>
            <Footer />
        </>
    );
}


export default shekomN;