import React from 'react';
import '../../App.css';
import Footer from '../Footer';



function aboutUs() {
    return (
        <>
            <h1 className='Page__title'> אודות</h1>
            <div className="about-us">
                <p> חברת הום מדיקל קיר    (HMC) נוסדה בשנת 2019
                    <br />
                    החברה מעניקה טיפול ביתי ע"י מערך אשפוז בית ( תחליף אשפוז ) ושיקום בית .
                    <br />
                    המנהל הרפואי של החברה הינו ד"ר אשרף חוסיני מומחה לרפואה פנימית , גריאטריה ופסיכוגריאטריה .
                    <br />
                    השירות  הרפואי ניתן ע"י רופאים מומחים בעלי ניסיון רב בתחום וצוות סיעודי ופרא רפואי ברמה מקצועית עליונה עם הרבה שנות ניסיון בתחום השיקום והאשפוז הגריאטרי .
                    <br />
                    השירות ניתן בזמינות 24/7.
                    <br />
                    החברה מתחייבת לתת שירות בכל רחבי ירושלים כולל מערב העיר , מזרח ירושלים ומעבר לגדר ההפרדה .
                    <br />
                    לשם כך בחרנו באנשי צוות שיכולים להגיע לכל מקום ברחבי עיר ירושלים ומעבר לגדר ההפרדה
                    הצוות דובר השפה העברית , הערבית ואנגלית  ע"מ להנגיש השירות בשפת האם של המטופל .
                    <br />
                    <u> החברה מתמחה בשירותי אשפוז בית  בתחומים הנ"ל : </u>
                </p>
                <ul>
                    <li>הוספיס בית – טיפול פליאטיבי</li>
                    <li>סיעודי מורכב </li>
                    <li>שיקום אורתופדי </li>
                    <li>שיקום נוירולוגי </li>
                    <li>שיקום קימום</li>
                    <li>טיפולי פרא רפואי ביתי כולל פיזיותרפיה , ריפוי בעיסוק , קלינאי תקשורת , עובדת סוציאלית ,  תזונה </li>
                </ul>
                <img className="about-img" src='images/hmc_img_7.jpg' />
            </div>


            <Footer />
        </>
    );
}


export default aboutUs;