export default function validateInfo(values) {
    let errors = {};
  
    if (!values.username.trim()) {
      errors.username = 'חובה להזין שם מלא !';
    }
    // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }
  
    if (!values.email) {
      errors.email = 'חובה להזין כתובת אימייל !';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'חובה להזין כתובת אימייל תקינה  !';
    }
    if (!values.tel) {
      errors.tel = 'חובה להזין מס טלפון !';   
    }
  
    if (!values.message) {
      errors.message = 'חובה להזין הודעה !';   
    }
    return errors;
  }