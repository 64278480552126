import React from 'react';
import '../Paragraph.css';

import Footer from '../Footer';
import Paragraph from '../Paragraph';
import ParagraphImage from '../ParagraphImage';


function bnemet() {
    return (
        <>
            <h1 id="linkUp" className='Page__title'>פנימית בית</h1>
            <ul className='Paragraphs__items'>
                <div id="linkUp" />
                <ParagraphImage
                    head=' '
                    prg_img='images/hmc_img_6.jpg'
                    text={[
                        'הום מידיקל קיר מעניקה שרות של פנימית בית ',
                        <br />,
                        'משך האשפוז במחלקה לאשפוז ביתי יכול להגיע עד חודש ימים ',
                        <br />,
                        'יועמד  לרשות המטופל מוקד 24/7 ',
                        <br />,
                        'במהלך האשפוז יבוצע ביקור פרונטלי יומי ע"י רופא מומחה ברפואה פנימית ואח מוסמך',
                        <br />,
                        'תנאי לאשפוז בית הנו נוכחות מטפל עיקרי למשך 24 שעות ביממה',
                        <br />,
                        'מטרת האשפוז הביתי הוא מתן הטיפול עם שמירה על איכות החיים בסביבה הנוחה  של המטופל עם שמירה על כבוד המטופל ומשפחתו , הפחתת האשפוזים , מניעת זיהומים , הארכת תוחלת החיים .'
                    ]}
                />
                <div id="link1" />

                <div id="link2" />


                <Paragraph
                    head='איך זה עובד : '
                    text={[<ul>
                        <li>רופא ואחות יגיעו לביקור ראשון תוך 2-4 שעות מקבלת הפנייה </li>
                        <li>לאחר הערכה ראשונית וקביעת תכנית טיפול  </li>
                        <li>יבקר כל יום רופא ואחות , במקרים דחופים  יבקר הרופא תוך שעה מהדיווח ע"י המטופל או משפחתו </li>
                        <li>לאחר מקביעת תכנית הטיפול יבקר הצוות הרפואי בהתאם ליישום הטיפול בשיתוף מלא עם המטופל ובני המשפחה </li>
                        <li>המטופל ובני המשפחה שותפים מלאים בטיפול </li>
                        <li>הצוות ינטר המטופל סבב השעון ויהיה זמין בהתאם </li>
                    </ul>
                    ]}
                />

                <Paragraph
                    head='מה יכלול הטיפול ?'
                    text={[<ul>
                        <li>מתן תרופות דרך הוריד</li>
                        <li>מתן נוזלים </li>
                        <li>ניטור סימנים חיוניים </li>
                        <li>איזון כאב </li>
                        <li>טיפול באינהלציה </li>
                        <li>בדיקות מעבדה </li>
                        <li>ביצוע אקג (במידת הצורך) </li>
                        <li>מתן חמצן (במידת הצורך)</li>
                        <li>שטיפת צנתרים  (במידת הצורך)</li>
                        <li>טיפול בפצעים (במידת הצורך)</li>
                    </ul>
                    ]}
                />

                <Paragraph
                    head='למי זה מתאים : '
                    text={['חולים עם המחלות הבאות :',
                        <ul>
                            <li>דלקת בדרכי השתן   urinary tract infection</li>
                            <li>אי ספיקת לב     congestive  heart failure</li>
                            <li>דלקת בעור  Cellulitis</li>
                            <li>מחלות ריאה COPD    Pneumonia</li>
                            <li>התייבשות      dehydration</li>
                        </ul>
                    ]}
                />


                <div id="link3" />



            </ul>

            <Footer />
        </>
    );
}


export default bnemet;