import React from 'react';
import './SubMenu.css';
import SubMenuItem from './SubMenuItem';
//'/{props.path}#link1'
function subMenu(props) {
  return (
    <div className='subMenu'>     
      <div className='subMenu__container'>
        <div className='subMenu__wrapper'>     
          <ul className='subMenu__items'>
            <SubMenuItem
              src='images/hmcTarget.png'
              text='מטרת הטיפול הפליאטיבי'             
              path={'/'+props.path+'#link1'}
              
            />
            <SubMenuItem
              src='images/hmcPerson.png'
              text='למי זה מתאים'              
              path={'/'+props.path+'#link2'}
            />
            <SubMenuItem
              src='images/hmcAsk.png'
              text='שאלות ותשובות'          
              path={'/'+props.path+'#link3'}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default subMenu;
