import React from 'react';
import '../Paragraph.css';

import Footer from '../Footer';
import Paragraph from '../Paragraph';
import ParagraphImage from '../ParagraphImage';


function shekomK() {
    return (
        <>
            <h1 id="linkUp" className='Page__title'> שיקום קימום ( לאחר אשפוז ממושך )  </h1>
            <ul className='Paragraphs__items'>
                <div id="linkUp" />
                <ParagraphImage
                    head=' '
                    prg_img='images/hmc_img_4.jpg'
                    text={[
                        'חלק מאנשים במיוחד המבוגרים יצטרכו לשיקום והסתגלות מחדש לאחר שחרור מאשפוז ממושך הטיפול ניתן בביתו של המטופל והתהליך בד"כ ארוך טווח ומורכב.',
                        <br />,
                        'הטיפול במסגרת שיקום קימום ניתן ע"י צוות רב מקצועי.',
                        <br />,
                        'המשפחה של המטופל שותפים מלאים לתכנית הטיפול.',
                        <br />,
                        'תכנית טיפול תותאם לכל מטופל לפי צרכיו בתיאום עם המטופל ומשפחתו עם דגש על כבודו והערכים שלהם.',
                        <br />,
                        'הצוות הרפואי מלווה המטופל 24/7.',
                        <br />,
                        'חייב נוכחות מטפל עיקרי למטופל אשר ידאג למטופל סביב השעון.',
                        <br />,
                        'מנהל הטיפול אשר יוגדר ( רופא /אח ) יהיה זמין 24/7 עבור המטופל ומשפחתו.'
                    ]}
                />
                <div id="link1" />

                <div id="link2" />


                <Paragraph
                    head='למי מיועד השירות ? '
                    text={[<ul>
                        <li>לאחר אשפוז ממושך</li>
                        <li>חולים לאחר ניתוח מורכב </li>
                        <li>מצבים רפואיים מורכבים הזקוקים להמשך ליווי רפואי.</li>
                    </ul>
                    ]}
                />


                <Paragraph
                    head='תכנית שיקום '
                    text={[
                        'הצוות הרפואי כולל צוות ייעודי קבוע המלווה את המטופל – רופא/ה, אח/ות, עובד/ת סוציאלי/ת, י וליווי צוות רב תחומי בהתאם לצורך – פיזיותרפיסט/ית, קלינאי/ת תקשורת, מרפא/ה בעיסוק, דיאטנ/ית בהתאם לצרכים של המטופל.',
                        <br />,
                        'בשלב הראשון ייעשה ביקור הערכה ע”י רופא מומחה בכדי להתאים לחולה תכנית טיפול אישית המותאמת לצרכיו הרפואיים.',
                        <br />,
                        'תכנית הטיפול תכלול אנשי הצוות הרפואי והפרא רפואי , תקופת הטיפול , כמות ביקורים לכל איש צוות , יעדים .',
                        <br />,
                        'הצוות הנו קבוע, זמין טלפונית למטופל ולמשפחתו בכל רגע נתון 24/7.'
                    ]}
                />
                <div id="link3" />



            </ul>

            <Footer />
        </>
    );
}


export default shekomK;