import React from 'react';
import './Customers.css';
//'/{props.path}#link1'
function customers(props) {
  return (
    <div className="customer" >     
      <h1 className='customerTitle'>שותפים לדרך</h1>
      <div className='customeContainer'>
        <img className='customerImg' src='images/mohedet.png'/>
        <img className='customerImg' src='images/lomet.png'/>
      </div> 
    </div>     
  );
}

export default customers;
