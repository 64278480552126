import React from 'react';
import '../../App.css';
import Cards from '../Cards';


export default function Services() {
  return (
     <>
     <h1 className='services'>שיקום בית</h1>;   
     
    </>
  );
}
