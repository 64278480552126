import React from 'react';
import '../Paragraph.css';

import Footer from '../Footer';
import Paragraph from '../Paragraph';
import ParagraphImage from '../ParagraphImage';
import SubMenu2 from '../SubMenu2';

function shekom() {
  return (
    <>
      <h1 id="linkUp" className='Page__title'> שיקום בית </h1>
      <ul className='Paragraphs__items'>
        <div id="linkUp" />
        <ParagraphImage
          head=' '
          prg_img='images/hmc_img_4.jpg'
          text={['שיקום הוא תהליך אינטנסיבי בו משתתפים אנשי מקצוע מגוונים, המסייע לאנשים בעלי לקות (לקות פיזיות, נפשית, חושיות או קוגניטיביות) לשפר את התפקוד שלהם באופן משמעותי.',
            <br />,
            ' מטרת השיקום היא עצמאות תפקודית ואיכות חיים. ',
            <br />,
            'מיצוי הפוטנציאל מתייחס לשאיפה לשחזר ולאחזר בצורה המיטבית את היכולות הקודמות, תוך כדי פיתוח אסטרטגיות התמודדות ופיצוי עם היכולות שנותרו או שוקמו בחלקן. ',
            <br />,
            'במקרים רבים השיקום כולל פיתוח יכולות המאפשרות למשתקם להשתלב בסביבה המתאימה ביותר להגשמת מאוויו האישיים בנוגע לעצמאות פיזית, אינטלקטואלית וכלכלית, וזאת דרך התערבות פסיכולוגית, ארגונית, חברתית והתנהגותית.',
            <br />,
            'ברוב המקרים שיקום נעשה בשילוב של צוות רב תחומי: רופאים, אחיות, פיזיותרפיסטים, פסיכולוגים, מרפאים בעיסוק, עובדים סוציאליים ועוד. תהליך זה מתרחש בהקשר מערכתי, ולכל ההיבטים המערכתיים ישנה השפעה המשתלבת לכדי תמונת התמודדות כללית.'
          ]}

        />
        <div id="link1" />

        <div id="link2" />

        <Paragraph
          head='החברה מעניקה שיקום בית בתחומים הבאים:  '
          text={[<u>שיקום נוירולוגי</u>,
            " - בעקבות אירוע מוחי, פגיעות ראש, פגיעות חוט שדרה, מחלות שרירים ומחלות נוירולוגיות.", <br />,
          <u>שיקום אורתופדי</u>,
            " - בעקבות שברים, קטיעות גפיים, ניתוחי גב, החלפות מפרקים.", <br />,
          <u>שיקום קימום</u>,
            " - בעקבות ירידה תפקודית לאחר מחלה חריפה, בעת מחלות מורכבות ומחלות ניווניות מתקדמות ולאחר ניתוחים."
          ]}
        />
        <SubMenu2 path='shekom' />
        <Paragraph
          head='מטרת שיקום בית : '
          text={['כל אדם מבוגר שעבר אירוע כלשהו שפגע בבריאותו וביכולת התפקוד שלו זכאי לעבור תהליך שיקום שישפר ככל האפשר את מצב בריאותו ואת יכולות התפקוד שלו.',
            <br />,
            'שיקום הוא שלב חשוב בתהליך הריפוי, ומטרתו היא להחזיר את הנפגע לתפקוד מרבי ולסגנון חיים פעיל ובריא.',
            <br />,
            'במקרים רבים עדיף לנפגע לחזור מהר ככל האפשר לביתו, לסביבה המוכרת לו, שכן הדבר עשוי להשפיע עליו לטובה מהבחינה הנפשית והחברתית ולתרום להבראה מהירה יותר ולשיקום טוב יותר. למען אלה שרוצים ויכולים לחזור הביתה מהר ככל האפשר מגישה כללית את המדריך לשירותי שיקום בבית.'

          ]}
        />
        <div id="link3" />

        <Paragraph
          head='תכנית הטיפול השיקומי :  '
          text={[
            <ul>
              <li>עם קליטת המטופל תותאם חבילת טיפול ע"י רופא מומחה שיקום .</li>
              <li>המטופל יקבל שרות ע"י צוות רפואי ופרא רפואי אשר כולל רופא , אחות , פיזיותרפיסט , מרפא בעיסוק , קלינאי תקשורת , עובדת סוציאלית , דיאטנית .</li>
              <li>רופא מומחש שיקום ילווה הטיפול ויתאים הטיפולים בהתאם לצרכים הייחודים של אותו מטופל .</li>
              <li>הצוות המטפל ילווה המטופל ומשפחתו לאורך כל תקופת הטיפול .</li>
              <li>מנהל הטיפול אשר יוגדר לכל מטופל יהיה זמין טלפוני 24/7 .</li>
              <li>עובדת סוציאלית תלווה המטופל למיצוי זכויות ותמיכה נפשית ורגשית בהתאם . </li>
              <li>תבוצע התאמת דיור  בהתאמה לכל מטופל והצרכים שלו  .</li>
            </ul>
          ]}
        />

        <Paragraph
          head='שאלות ותשובות : '
          text=''
        />
        <Paragraph
          head='מי זכאי לשיקום אשפוזי (במוסד או בבית)?'
          text='ל מטופל מלווה ע"י צוות מקצועי קבוע הכולל אח/ות, רופא/ה ועובד/ת סוציאלי. האחות היא מנהלת הטיפול והיא תערוך את הביקור הראשון בבית המטופל תוך 24 שעות מקליטתו. הצוות המטפל יערוך ביקורים באופן קבוע, יהיה זמין עבורכם בכל רגע נתון 24/7, וילווה אתכם בכל צורך שיתעורר  רפואי ורגשי.'
        />
        <Paragraph
          head='לאמי נקבע ניתוח להחלפת מפרק. מה השיקום הצפוי לה?'
          text='לאחר ניתוח החלפת מפרק, קיימים מקרים בהם הרופא קובע הגבלות לאחר הניתוח, כגון איסור דריכה או הגבלת תנועה, למרות זאת החולה יופנה לשיקום באשפוז - במוסד או בבית. יש להתחיל טיפול שיקומי מוקדם מאוד לאחר ההתאוששות מהניתוח ובדרך כלל כבר מספר ימים לאחריו בכדי לאפשר שיקום מיטבי. במקרים בהם קופת חולים לא מאשרת שיקום, יש לפנות לאגודה לזכויות החולה או לנציבת קבילות הציבור במשרד הבריאות.'
        />
        <Paragraph
          head='מתי וממי מקבלים הפנייה לשיקום?'
          text='ההפנייה לשיקום נעשית בבית החולים לקראת השחרור. בהחלטה על מסגרת השיקום המתאימה לאדם, יש לשתף את המטופל ומשפחתו ולהתחשב בהעדפותיו תוך מתן מידע מלא על הגורמים בהם ניתן להסתייע בתהליך השיקום. מידע נוסף ניתן למצוא באתר משפחה מטפלת.'
        />
        <Paragraph
          head='מהו משך זמן השיקום המוגדר עפ”י החוק?'
          text='משך הזמן הניתן לשיקום לפי חוק בריאות ממלכתי הוא עד 90 יום. בשיקום אורתופדי – משך זמן האשפוז הוא מינימום שבועיים. בשיקום נוירולוגי – משך זמן האשפוז הוא מינימום 25 יום ועד 3 חודשים. במידה ונקבע משך זמן קצר יותר לאשפוז במחלקה שיקומית, הגורם המבטח = קופות החולים חייב לתת נימוק לאשפוז הקצר.'
        />
        <Paragraph
          head='מתי החולה זכאי לשיקום?'
          text='מחלה או פגיעה גופנית, בגיל המבוגר עשויות להוביל להתדרדרות ולירידה בתפקוד. ההחלמה והחזרה לתפקוד בגיל הזקנה עשויה להיות מורכבת ומחייבת בדרך כלל תהליך שיקומי. מטרת השיקום היא להחזיר את האדם לתפקוד מרבי בסביבתו הטבעית. השיקום בגיל המבוגר הוא זכות המעוגנת בחוק ביטוח בריאות ממלכתי והוא באחריות קופת החולים בה מבוטח האדם.'
        />
        <Paragraph
          head='מי יטפל בנו בבית?'
          text='כל מטופל מלווה ע"י צוות שיקומי קבוע הכולל תחומי התמחות שונים בהתאם לתוכנית הטיפול – פיזיותרפיסט/ית, מרפא/ה בעיסוק, קלינאי/ת תקשורת ויעוץ של דיאטנ/ית. לצד הצוות השיקומי, המטופל מלווה גם ע"י צוות מקצועי קבוע הכולל רופא/ה, אחות ועו"ס. הצוות המטפל עובד בשיתוף פעולה ובתיאום מלא, ומנוהל ע"י הפיזיותרפיסט/ית המשמש כמנהל תיק המטופל.'
        />
        <Paragraph
          head='במשך כמה זמן אקבל את הטיפול?'
          text='תכנית הטיפול מוגדרת בהתאם למצב הרפואי של המטופל, ולאחר תהליך אבחון והערכה שאנו מבצעים נוכל להעריך את משך הזמן הנדרש לטיפול. כאשר מדובר במטופל שיקום המגיע אלינו דרך קופת החולים, משך זמן הטיפול יוגדר בתיאום מלא מול קופת החולים לאחר בניית תכנית טיפול והמלצות הצוות המקצועי.'
        />
        <Paragraph
          head='כמה פעמים בשבוע הצוות יגיע לטיפול?'
          text='תכנית הטיפול המלאה נבנית בהתאם למצב הרפואי של המטופל, ולאחר תהליך אבחון והערכה שאנו מבצעים.'
        />
        <Paragraph
          head='איך נערכים בבית עם ציוד / מכשירים הנדרשים לשיקום?'
          text='הצוות שלנו מחזיק ציוד בסיסי ברכב, אשר נותן את המענה ברוב המקרים. במידה ואנחנו מתרשמים שהמטופל זקוק למכשיר נוסף, אנו נשקף זאת לקופת החולים בכדי לקבל את אישורם לכך. במידה והמטופל הגיע אלינו באופן פרטי, ניתן לו את הייעוץ לרכישת הציוד המתאים.'
        />
        <Paragraph
          head='למה עדיף לעבור שיקום בבית ולא במוסד שיקומי בו יש השגחה רפואית צמודה 24/7?'
          text='אנחנו מאמינים שתמיד טוב יותר בבית, וברוב המקרים אנשים ירגישו ויחלימו טוב יותר בבית. אנו מתאימים למטופל תכנית שיקום ייעודית לצרכיו אשר מותאמת גם לסביבה הפיזית בה הוא חי. במוסד שיקומי קיימת סכנת חשיפה לזיהומים ובמקרים רבים זמן ההמתנה ארוך, ולכן יכול להשפיע על יכולת מיצוי הפוטנציאל השיקומי. כמו כן, במקרים רבים לא נדרשת השגחה רפואית צמודה, ולמעשה המטופל עסוק זמן קצר במהלך היום בפעילות שיקומית ובשאר היום הוא מבלה בסביבה זרה הרחוקה מלהיות בית.'
        />
        <Paragraph
          head='במידה ומתעוררת בעיה, למי אני פונה?'
          text='כל מטופל מקבל את פרטי ההתקשרות של הצוות המטפל למידת הצורך. אנחנו זמינים עבורכם 24/7.'
        />


      </ul>
      <Footer />
    </>
  );
}


export default shekom;