import React from 'react';
import '../Paragraph.css';

import Footer from '../Footer';
import Paragraph from '../Paragraph';
import ParagraphImage from '../ParagraphImage';
import SubMenu from '../SubMenu';


function  Products (){ 
  return (  
    <>
    <h1 className='Page__title'> סיעודי מורכב </h1>  
    <ul className='Paragraphs__items'>  
                  
                  <ParagraphImage                   
                    head=' '
                    prg_img='images/hmc_img_5.jpg'
                    text='השרות של הוספיס בית מתאים לחולים אשר סובלים  ממחלות קשות ונמצאים בשלב מתקדם וסופני של המחלה 
                          המטופלים הנ"ל דורשים לשהות התקופה האחרונה לחייהם בביתם , עם בני משפחתם ובסביבה שלהם 
                          הטיפול הפליאטיבי (הטיפול התומך) במסגרת הוספיס בית הוא חלק מגישה טיפולית שנועדה לשפר את איכות החיים של חולים ובני משפחותיהם, בהתמודדות עם מחלות חשוכות מרפא
                          השרות מיועד לחולים מכל הגילאים 
                          השרות ניתן ע"י רפואי כולל  רופאים , אחיות ועובדות סוציאליות דוברי השפה עברית , ערבית , אנגלית  
                          הצוות הרפואי מוכשר לטיפול במצבים מורכבים אלה כולל טיפול רפואי ורגשי במיוחד לחולים אלה בתקופה סופנית לחייהם . '
                  />      
                   <div  id="link1"/>     
                  <SubMenu path='Products'/> 
                  <div  id="link2"/>    
                  <Paragraph
                    head='מטרת הטיפול הפליאטיבי : '
                    text='להקל הכאב והסבל  על המטופל ממחלה חשוכת מרפא – באמצעות זיהוי התסמינים ומתן טיפול מתאים. 
                          תומך בהתמודדות הנפשית של המטופל ושל בני משפחתו המלווה את התהליך, במטרה שיוכלו לנהל חיים עצמאיים ומכבדים ככל האפשר למרות המחלה.
                          החולים ובני משפחותיהם שותפים בקבלת ההחלטות הטיפוליות
                          עוזר למטופל להמשיך חיים פעילים וסבירים ככל הניתן בהתאם ליכולות והמגבלות שלו . 
                          משפר איכות חיים ומשפר מצב רגשי ונפשי של המטופל 
                          אשפוז הבית ניתן רק במידה ויש לחולה מטפל עיקרי בבית שיכול לדאוג לצרכיו 24/7
                          צוות הרפואי ילווה המטפל העיקרי 24/7 וייתן מענה לכל השאלות והבירורים הזמן אמת. '           
                  />   
                    <div  id="link3"/>
                  
                  <Paragraph
                    head='למי זה מתאים :  '
                    text='כל אדם, אשר רופא מומחה קבע כי תוחלת חייו אינה עולה על 6 חודשים, זכאי לקבל טיפול פליאטיבי במסגרת אשפוז בית

                          סרטן
                          אי ספיקת לב (CHF)
                          מחלת ריאות חסימתית כרונית 
                          אי ספיקת כליות
                          כשל בכבד
                          מחלה נוירולוגית (כגון: ALS, פרקינסון)
                          דמנציה מתקדמת
                          מחלה מאיימת חיים אחרת.  '           
                  /> 
                
                  <Paragraph
                  head='שאלות ותשובות : '
                  text=''           
                  /> 
                  <Paragraph
                  head='מי יטפל בנו בהוספיס בית ?'
                  text='כל מטופל מלווה ע"י צוות מקצועי קבוע הכולל אח/ות, רופא/ה ועובד/ת סוציאלי. האחות היא מנהלת הטיפול והיא תערוך את הביקור הראשון בבית המטופל תוך 24 שעות מקליטתו. הצוות המטפל יערוך ביקורים באופן קבוע, יהיה זמין עבורכם בכל רגע נתון 24/7, וילווה אתכם בכל צורך שיתעורר  רפואי ורגשי.'           
                  /> 
                  <Paragraph
                  head='איך אני יכול לקבל את שירות ההוספיס שלכם ?'
                  text='במידה והקרוב לכם נמצא בבית החולים, עליכם לפנות לנציג הקופה בבית החולים. במידה והקרוב לכם נמצא בבית, עליכם לגשת לרופא המשפחה בכדי לקבל הפניה להוספיס בית. את ההפניה יפנה הרופא ליחידה לטיפולי בית של קופת החולים, ודרך יחידה זו תוכלו לקבל את ההפנייה אלינו. '           
                  /> 
                  <Paragraph
                  head='כמה יעלה לנו הוספיס בבית ?'
                  text='הוספיס בבית הוא שירות המגיע על פי חוק לכל אדם אשר רופא מעריך כי תוחלת חייו לא תעלה על 6 חודשים. השירות ניתן ללא כל עלות, במימון קופת החולים. בכדי לקבל שירות זה - יש לפנות לרופא המשפחה, ולקבל הפנייה לליווי במסגרת הוספיס. ההפנייה תעבור לאישור סופי מול היחידה להמשך טיפול של קופת החולים. '           
                  /> 
                     <Paragraph
                  head='אתם גם עוזרים בטיפול יומיומי כגון רחצה ?'
                  text='ישנן חברות המתמחות במתן כח עזר סיעודי, כגון "עמל סיעודית". הצוות שלנו  הוא צוות המתמחה במתן טיפול פליאטיבי תומך בהיבט הרפואי והרגשי. כחלק מהליווי שלנו, אנחנו ניתן לאדם הקרוב המטפל בחולה הנחיות בכל הקשור לטיפול הסיעודי. '           
                  /> 
                     <Paragraph
                  head='מה קורה אם אני צריך עזרה דחופה ?'
                  text='בכל מקרה של מצוקה, אנו זמינים עבורכם 24/7 כולל בסופי שבוע ובחגים. האח/ות ישאירו לכם את מספר הטלפון הנייד שלהם ומספר טלפון נוסף של כוננות למהלך סוף השבוע '           
                  /> 
                     <Paragraph
                  head='הקרוב שלי עדיין מקבל טיפולים כימותרפיים. האם נוכל במקביל לקבל את השירות שלכם ?'
                  text='בוודאי. הטיפול שאנחנו נותנים הוא טיפול פליאטיבי תומך, וניתן ואף רצוי לקבלו בכל שלב של המחלה, גם במקביל לקבלת טיפול פעיל במחלה (הקרנות, כימותרפיה,..). מטרת הטיפול הפליאטיבי היא להפחית את הסבל הפיזי, הרגשי והרוחני של החולה, הנובע מהמחלה או מהטיפול במחלה, באמצעות איזון כאב ותסמינים אחרים. קבלת טיפול פליאטיבי מאפשרת לחולה להמשיך בחיי היומיום בנוחות החיים הטובה ביותר האפשרית '           
                  /> 
                     <Paragraph
                  head='קרוב המשפחה שלי מקבל נוזלים וזריקות באופן שוטף. איך זה ימשיך להתקיים בבית ?'
                  text='כחלק מהאחריות הרפואית שלנו, אנו אחראים גם על נושא זה. האח/ות יתדרכו אתכם בכל ההליכים הנדרשים. '           
                  /> 
                  <Paragraph
                  head='ממי אנחנו מקבלים מרשמים ותרופות ?'
                  text='הצוות המטפל בכם מספק לכם מרשמים אשר מכובדים בבתי המרקחת של כל קופות החולים ואין צורך להמיר אותם אצל רופא המשפחה. עליכם לגשת עם המרשמים לבית המרקחת המכיר במרשמי קופת החולים שלכם, ולרכוש שם את התרופות '           
                  /> 
                  <Paragraph
                  head='הקרוב שלי סובל מכאבים חזקים ומטופל במורפין. האם גם מורפין אתם נותנים בבית ?'
                  text='באפשרותנו לתת כל תרופה לכל סימפטום, כולל שימוש במורפין. הצוות שלנו מתמחה באיזון סימפטומים כגון כאבים, עצירות, דיכאון, קשיים בשינה, חולשה וכל תופעת לוואי אחרת אשר מלווה אנשים אשר חולים במחלה קשה '           
                  /> 
                  <Paragraph
                  head='הקרוב שלי לא מודע למצבו הקשה. אנחנו לא רוצים לספר לו היות וזה יגמור אותו. מה עושים ?'
                  text='אנחנו נכבד כל בקשה שלכם ושל הקרוב לכם. בתהליך הליווי אנחנו בודקים בכל שלב מה החולה יודע ורוצה לדעת, ובהתאם לתשובתו אנו מנהלים עמו את הדיאלוג. אנחנו מכבדים את רצון המטופל ומשפחתו בכל בקשה, ונפעל בהתאם לכך '           
                  /> 
                  
    </ul>
    <Footer/>  
    </>
        );
}


export default Products;